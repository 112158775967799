<template>
  <div class="review-link-widget" v-show="setupComplete" :style="{'background-color':configuration.background_color}">
    <div class="row">
      <div class="col">
        <h3 class="h1 text-center" :style="{'color':configuration.main_text_color}">{{configuration.header_text}}</h3>
      </div>
    </div>
    <div class="row place-search-row">
      <div :class="{ 'col double-column': step === 1, 'col-12': step > 1 }">
        <input type="text" ref="placesearch" id="placesearch" placeholder="Start Typing your business to generate your Google review link..." :class="{ 'has-error': errors.business }" />
        <span v-if="errors.business" class="error" :style="{color:configuration.error_text_color}">{{ errors.business }}</span>
      </div>
      <div v-if="step === 1" class="col text-center" :class="{'center-content' : !errors.business }">
        <div v-if="isBusy" v-html="spinner"></div>
        <button v-else class="btn" @click="createProspect" :style="{'color':configuration.primary_button_text_color, 'background-color':configuration.primary_button_background_color}">Generate My Link</button>
      </div>
    </div>
    <div v-if="step === 2" class="row mt-3">
        <div class="col double-column">
          <div class="row">
            <div class="col-lg-6 mt-2">
              <input v-model="form.first_name" type="text" :class="{ 'has-error': errors.first_name }" placeholder="Your First Name Here..." />
              <span v-if="errors.first_name" class="error" :style="{color:configuration.error_text_color}">{{ errors.first_name }}</span>
            </div>
            <div class="col-lg-6 mt-2">
              <input v-model="form.last_name" type="text" :class="{ 'has-error': errors.last_name }" placeholder="Your Last Name Here..." />
              <span v-if="errors.last_name" class="error" :style="{color:configuration.error_text_color}">{{ errors.last_name }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-2">
              <input v-model="form.email" type="text" :class="{ 'has-error': errors.email }" placeholder="Your Email Address Here..." />
              <span v-if="errors.email" class="error" :style="{color:configuration.error_text_color}">{{ errors.email }}</span>
            </div>
            <div class="col-lg-6 mt-2">
<!--              <input v-model="form.phone_number" v-mask="'(###) ###-####'" type="tel" :class="{ 'has-error': errors.phone_number }" placeholder="Your Phone Number Here..." />-->
              <vue-tel-input
                  v-model="form.phone_number"
                  :input-options="{ showDialCode: false, tabindex: false, placeholder:'Your Phone Number Here...' }"
                  @input="onPhoneInput"
              ><template slot="arrow-icon"> <span class="vti__dropdown-arrow">&nbsp;▼</span></template></vue-tel-input>
              <span v-if="errors.phone_number" class="error" :style="{color:configuration.error_text_color}">{{ errors.phone_number }}</span>
            </div>
          </div>
        </div>
        <div class="col just-center text-center mt-4">
            <div v-if="isBusy" v-html="spinner"></div>
            <button v-else class="btn" @click="createLead" :style="{'color':configuration.primary_button_text_color, 'background-color':configuration.primary_button_background_color}">Get My Link</button>
        </div>
    </div>
    <div v-if="step === 3" class="row mt-3">
        <div class="col-md-6">
          <div class="link-display" :style="{'background-color': configuration.copy_area_background_color}">
            <div class="">
              <div class="intro-image"><img src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png" style="max-height:40px" alt=""></div>
              <div class="intro-text" :style="{'color': configuration.copy_area_text_color}">
                <strong>Get more reviews</strong>
                <em>Direct customers to leave a review for this business</em>
              </div>
            </div>
            <div class="copy-link">
              <input ref="linkText" v-model="customLink" disabled type="text" aria-label="Recipient's username" aria-describedby="basic-addon2">
              <button class="btn" @click="copyLink" :style="{'background-color':configuration.copy_button_background_color}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :style="{'fill': configuration.copy_button_text_color}"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z"/></svg></button>
              <transition name="fade">
                <div v-if="showCopied" style="text-align: right; margin-top: 2px">
                  Link Copied
                </div>
              </transition>
            </div>
          </div>
          <a target="_blank" :href="`https://search.google.com/local/writereview?placeid=${placeResultData.place_id}`" class="rl-mt-4 btn d-block view-link" :style="{'color':secondaryButtonTextColor, 'background-color':secondaryButtonBackgroundColor}">View Business Review Link</a>
          <a target="_blank" :href="placeResultData.url" class="rl-mt-4 btn view-map" :style="{'color':configuration.primary_button_text_color, 'background-color':configuration.primary_button_background_color}">View Business On Map</a>
        </div>
        <div class="col text-center center-content">
          <!-- eslint-disable vue/camelcase -->
          <iframe
              v-if="mapQuery"
              v-show="mapReady"
              class="map-frame"
              width="100%"
              height="265"
              style="border:0"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://www.google.com/maps/embed/v1/place?key=${google_api_key}&q=${mapQuery}`"
              @load="mapReady = true"
          >
          </iframe>
          <div v-if="!mapReady" v-html="spinner"></div>
        </div>
    </div>
    <div v-if="step === 3" class="row mt-3 qr-row">
      <hr />
      <div class="col double-column" :style="{'color':configuration.main_text_color}" v-html="configuration.qr_text"></div>
      <div class="col center-content">
        <div ref="qrcode">
          <QrcodeVue :value="customLink" :size="200" style="padding: 40px; background: #fff; border-radius: 8px; margin-bottom: 15px; margin-top: 15px" />
          <button class="btn" @click="downloadQrCode" :style="{'color':configuration.primary_button_text_color, 'background-color':configuration.primary_button_background_color}">Download</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from 'vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { countryToAlpha2 } from "country-to-iso";
import axios from "axios";
axios.defaults.baseURL = (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1'
import VueScript2 from "vue-script2";
import { VueMaskDirective } from 'v-mask'
import QrcodeVue from 'qrcode.vue'
import VueTelInput from 'vue-tel-input';
Vue.directive('mask', VueMaskDirective);
Vue.component('vue-tel-input', VueTelInput.VueTelInput)
const ADDRESS_COMPONENTS = {
  subpremise : 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
};
export default {
  components:{
    VueGoogleAutocomplete,
    QrcodeVue,
  },
  props:{
    startingStep:{
      type:Number,
      default:1
    },
    // eslint-disable-next-line vue/prop-name-casing
    agencyId: {
      type:[Number,String],
      required:true
    },
    headerText:{
      type:String,
      default:'Find Your Business'
    },
    // eslint-disable-next-line vue/prop-name-casing
    googleApiKey:{
      type:String,
      default:''
    },
    backgroundColor:{
      type:String,
      default:'#0e8dc7'
    },
    mainTextColor:{
      type:String,
      default:'#ffffff'
    },
    primaryButtonBackgroundColor:{
      type:String,
      default:'#34A853'
    },
    primaryButtonTextColor:{
      type:String,
      default:'#ffffff'
    },
    secondaryButtonBackgroundColor:{
      type:String,
      default:'#FFBE0B'
    },
    secondaryButtonTextColor:{
      type:String,
      default:'#ffffff'
    },
    copyAreaBackgroundColor:{
      type:String,
      default:'#EFF3F7'
    },
    copyAreaTextColor:{
      type:String,
      default:'#596482'
    },
    copyButtonBackgroundColor:{
      type:String,
      default:'#537BBA'
    },
    copyButtonTextColor:{
      type:String,
      default:'#ffffff'
    },
    spinnerColor:{
      type:String,
      default:'#000000'
    },
    errorTextColor:{
      type:String,
      default:'#4e0202'
    },
    defaultMapQuery:{
      type:String,
      default:''
    },
    qrText:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      phone_valid:false,
      step:this.startingStep,
      street:'',
      place:null,
      addressData:null,
      google_api_key:this.googleApiKey,
      reduced_address:null,
      placeResultData: {
        place_id:'',
        url:''
      },
      autocompleteText:'',
      placeFields:['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity', 'website', 'formatted_phone_number'],
      scriptLoaded:false,
      mapReady:false,
      isBusy:false,
      setupComplete:false,
      showCopied:false,
      mapQuery:this.defaultMapQuery,
      errors:{},
      unique_id:'',
      form:{
        first_name:'',
        last_name:'',
        email:'',
        phone_number:'',
        business_name:'',
        business_phone:'',
        business_website:'',
        address:'',
        city:'',
        state:'',
        zip:'',
        country:'',
        lat:'',
        lng:'',
        place_id:'',
        google_maps_url:'',
        agency_id:'',
        types:[],
        origin:''
      },
      configuration:{
        background_color:this.backgroundColor,
        main_text_color:this.mainTextColor,
        primary_button_background_color:this.primaryButtonBackgroundColor,
        primary_button_text_color: this.primaryButtonTextColor,
        copy_area_background_color: this.copyAreaBackgroundColor,
        copy_area_text_color: this.copyAreaTextColor,
        copy_button_background_color: this.copyButtonBackgroundColor,
        copy_button_text_color: this.copyButtonTextColor,
        error_text_color:this.mainTextColor,
        header_text:this.headerText,
        qr_text:this.qrText
      }
    }
  },
  watch:{
    'form.first_name': function(newVal, oldVal){
      if(newVal && this.errors.hasOwnProperty('first_name')){
        Vue.delete(this.errors,'first_name');
      }
    },
    'form.last_name': function(newVal, oldVal){
      if(newVal && this.errors.hasOwnProperty('last_name')){
        Vue.delete(this.errors,'last_name');
      }
    },
    'form.email': function(newVal, oldVal){
      if(newVal && this.errors.hasOwnProperty('email')){
        Vue.delete(this.errors,'email');
      }
    },
    'form.phone_number': function(newVal, oldVal){
      if(newVal && this.errors.hasOwnProperty('phone_number')){
        Vue.delete(this.errors,'phone_number');
      }
    },
    startingStep: function(newVal){
      this.step = newVal;
    },
    backgroundColor:function(newVal){
      this.configuration.background_color = newVal;
    },
    mainTextColor:function(newVal){
      this.configuration.main_text_color = newVal;
    },
    primaryButtonBackgroundColor:function(newVal){
      this.configuration.primary_button_background_color = newVal;
    },
    primaryButtonTextColor:function(newVal){
      this.configuration.primary_button_text_color = newVal;
    },
    copyAreaBackgroundColor:function(newVal){
      this.configuration.copy_area_background_color = newVal;
    },
    copyAreaTextColor:function(newVal){
      this.configuration.copy_area_text_color = newVal;
    },
    copyButtonBackgroundColor:function(newVal){
      this.configuration.copy_button_background_color = newVal;
    },
    copyButtonTextColor:function(newVal){
      this.configuration.copy_button_text_color = newVal;
    },
    errorTextColor:function(newVal){
      this.configuration.error_text_color = newVal;
    },
    headerText:function(newVal){
      this.configuration.header_text = newVal;
    },
    qrText:function(newVal){
      this.configuration.qr_text = newVal;
    }
  },
  computed: {
    addressString(){
      let address_string = '';
      if(this.placeResultData.hasOwnProperty('street_number') && this.placeResultData.hasOwnProperty('route')){
        address_string = this.placeResultData.street_number + ' ' + this.placeResultData.route;
        if(this.placeResultData.hasOwnProperty('subpremise')){
          address_string += ' '+this.placeResultData.subpremise;
        }
      }else if(this.placeResultData.hasOwnProperty('formatted_address')){
        address_string = this.placeResultData.formatted_address.split(',')[0]
      }
      return address_string;
    },
    customLink(){
      return (process.env.NODE_ENV === 'production' ? 'https://reviewour.biz' : window.location.origin) +'/r/'+this.unique_id;
    },
    spinner() {
      return `<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="${this.spinnerColor}">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"/>
            </path>
          </g>
        </g>
      </svg>`;
    }
  },

  mounted() {
    if(!this.googleApiKey){
      // We are being loaded from outside of site and need to get the API Key
      axios.post('/agency-data', {agency_id:this.agencyId})
          .then((res) => {
            const embed_settings = res.data.data.embed_form_settings;

            this.configuration.header_text = embed_settings.title;
            this.configuration.background_color = embed_settings.background_color;
            this.configuration.main_text_color = embed_settings.main_text_color;
            this.configuration.primary_button_background_color = embed_settings.primary_button_background_color;
            this.configuration.primary_button_text_color = embed_settings.primary_button_text_color;
            this.configuration.secondary_button_background_color = embed_settings.secondary_button_background_color;
            this.configuration.secondary_button_text_color = embed_settings.secondary_button_text_color;
            this.configuration.copy_area_background_color = embed_settings.copy_area_background_color;
            this.configuration.copy_area_text_color = embed_settings.copy_area_text_color;
            this.configuration.copy_button_background_color = embed_settings.copy_button_background_color;
            this.configuration.copy_button_text_color = embed_settings.copy_button_text_color;
            this.configuration.qr_text = embed_settings.qr_text;
            this.google_api_key = res.data.data.google_api_key;
            VueScript2.load(`https://maps.googleapis.com/maps/api/js?key=${this.google_api_key}&libraries=places`).then(() => {
              this.initMaps();
              this.setup();
            })
          })
          .catch((error) => {
            //eslint-disable-next-line no-console
            console.log(error)
          })
    }else{
      this.google_api_key = this.googleApiKey;
      VueScript2.load(`https://maps.googleapis.com/maps/api/js?key=${this.google_api_key}&libraries=places`).then(() => {
        this.initMaps();
        this.setup();
      })
    }
    this.form.agency_id = this.agencyId;
  },

  methods: {
    onPhoneInput(input,phoneObject){
      this.phone_valid = typeof phoneObject == 'object' && phoneObject.valid;
    },
    setup(){
      this.autocomplete = new google.maps.places.Autocomplete(
          this.$refs.placesearch,
          {
            types:['establishment']
          }
      );
      this.autocomplete.setFields(this.placeFields);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
      this.setupComplete = true;
    },
    /**
     * When a place changed
     */
    onPlaceChanged() {
      Vue.delete(this.errors, 'business');
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place);
        return;
      }
      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.selected(this.formatResult(place), place);
        // update autocompleteText then emit change event
      }
    },
    downloadQrCode() {
      console.log(this.$refs.qrcode);
      let canvasImage = this.$refs.qrcode.getElementsByTagName('canvas')[0].toDataURL('image/png');
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = 'qrcode.png';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      xhr.open('GET', canvasImage);
      xhr.send();
    },
    copyLink() {
      navigator.clipboard.writeText(this.customLink);
      this.showCopied = true;
      setTimeout(() => this.showCopied = false, 2000)
    },
    resetErrors(){
      this.errors = {};
    },
    createProspect(){
      this.isBusy = true;
      // this.resetErrors();
      if(this.addressData === null){
        this.errors.business = "Please select your business before trying to generate the link";
      }
      if(this.addressData !== null && (!this.placeResultData.hasOwnProperty('place_id') || (this.placeResultData.hasOwnProperty('place_id') && this.placeResultData.place_id === ""))){
        this.errors.business = "Sorry, but we could not find a place ID identifier for your business";
      }
      if(Object.keys(this.errors).length){
        this.isBusy = false;
        return
      }
      this.reduced_address = this.placeResultData.address_components.reduce((seed, { long_name, types }) => {
        types.forEach(t => {
          seed[t] = long_name;
        });
        return seed;
      }, {});
      const country = countryToAlpha2(this.addressData.country);
      let city = '';
      let address = '';
      if(country === 'US'){
        city = this.reduced_address.hasOwnProperty('neighborhood') ? this.reduced_address.neighborhood : this.addressData.locality;
        address = this.addressString;
      }else{
        city = this.reduced_address.hasOwnProperty('postal_town') ? this.reduced_address.postal_town : this.addressData.locality
        /* if(this.reduced_address.hasOwnProperty('premise')){
          address += this.reduced_address.premise + ' ';
        } */
        address += this.reduced_address.street_number + ' ' + this.reduced_address.route;
      }
      this.form.business_name = this.placeResultData.name;
      this.form.business_phone = this.placeResultData.formatted_phone_number;
      this.form.business_website = this.placeResultData.website;
      this.form.address = address;
      this.form.city = city;
      this.form.state = this.addressData.administrative_area_level_1;
      this.form.zip = this.addressData.postal_code;
      this.form.country = country;
      this.form.lat = this.addressData.latitude;
      this.form.lng = this.addressData.longitude;
      this.form.place_id = this.placeResultData.place_id;
      this.form.google_maps_url = this.placeResultData.url;
      this.form.types = this.placeResultData.types;
      this.form.origin = window.location.href;
      axios.post('/create-lead',this.form)
          .then((res) => {
            this.unique_id = res.data.data.unique_id;
            this.step++;
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            //eslint-disable-next-line no-console
            console.log(error)
          })
    },
    checkErrors(){
      if(this.form.first_name === ''){
        this.errors.first_name = "Please enter First Name";
      }
      if(this.form.last_name === ''){
        this.errors.last_name = "Please enter Last Name";
      }
      if(!/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.form.email)){
        this.errors.email = "Please enter a valid email"
      }
      if(!this.phone_valid){
        this.errors.phone_number = "Please enter a valid phone number"
      }
    },
    createLead(){
      this.isBusy = true;
      // this.resetErrors();
      this.checkErrors();
      if(this.form.business_name === ''){
        // Somehow they are here and a prospect was not created
        this.createProspect();
      }
      if(Object.keys(this.errors).length){
        this.isBusy = false;
        return
      }
      axios.post('/update-lead',this.form)
          .then(() => {
            this.step++;
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            //eslint-disable-next-line no-console
            console.log(error)
          })
    },
    initMaps(){
      this.scriptLoaded = true;
      // this.$refs.address.focus();
    },
    selected(addressData, placeResultData){
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      this.mapQuery = encodeURIComponent(placeResultData.name+' '+placeResultData.formatted_address);
    },
    updateAddress(){
      if(this.placeResultData) {
        this.$refs.address.update(this.placeResultData.name + ' ' + this.placeResultData.formatted_address);
      }
    },
    /**
     * Format result from Geo google APIs
     * @param place
     * @returns {{formatted output}}
     */
    formatResult (place) {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (ADDRESS_COMPONENTS[addressType]) {
          let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }
      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();
      return returnData
    },
  },
}
</script>
<style>
@import "../../src/assets/css/review-form.css";
</style>
